/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Paper, Stack, Typography, Tooltip, Divider } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { quickConfigDetails, sspSubmitOrder, toggleQuickConfig } from 'pages/provisioning/store/vm';
import {
    sspSubmitOrder as sqlOrder,
    toggleQuickConfig as sqltoggleQuickConfig,
    quickConfigDetails as sqlquickConfigDetails,
} from 'pages/provisioning/store/sql-paas';
import {
    sspSubmitOrder as oracleOrder,
    toggleQuickConfig as oracletoggleQuickConfig,
    quickConfigDetails as oraclequickConfigDetails,
} from 'pages/provisioning/store/oracle';
import {
    sspSubmitOrder as sqlIassOrder,
    toggleQuickConfig as sqlIasstoggleQuickConfig,
    quickConfigDetails as sqlIassquickConfigDetails,
} from 'pages/provisioning/store/iaas-sql';
import {
    sspSubmitOrder as storageOrder,
    toggleQuickConfig as storagetoggleQuickConfig,
    quickConfigDetails as storagequickConfigDetails,
} from 'pages/provisioning/store/storage';
import {
    sspSubmitOrder as aksnsOrder,
    toggleQuickConfig as aksnstoggleQuickConfig,
    quickConfigDetails as aksnsquickConfigDetails,
} from 'pages/provisioning/store/aksns';
import {
    sspSubmitOrder as keyVaultOrder,
    toggleQuickConfig as keyVaulttoggleQuickConfig,
    quickConfigDetails as keyVaultquickConfigDetails,
} from 'pages/provisioning/store/key-vault';
import {
    sspSubmitOrder as albOrder,
    toggleQuickConfig as albtoggleQuickConfig,
    quickConfigDetails as albquickConfigDetails,
} from 'pages/provisioning/store/alb';
import {
    sspSubmitOrder as day2Order,
    toggleQuickConfig as day2toggleQuickConfig,
    quickConfigDetails as day2quickConfigDetails,
} from 'pages/provisioning/store/day2-operation';
import {
    sspSubmitOrder as day2PaaSOrder,
    toggleQuickConfig as day2PaaStoggleQuickConfig,
    quickConfigDetails as day2PaaSquickConfigDetails,
} from 'pages/provisioning/store/day2-operation-paas';
import {
    sspSubmitOrder as kafkaOrder,
    toggleQuickConfig as kafkatoggleQuickConfig,
    quickConfigDetails as kafkaquickConfigDetails,
} from 'pages/provisioning/store/kafka';
import {
    sspSubmitOrder as privateEnpointOrder,
    toggleQuickConfig as privateEnpointToggleQuickConfig,
    quickConfigDetails as privateEnpointQuickConfigDetails,
} from 'pages/provisioning/store/private-endpoint';
import {
    sspSubmitOrder as eventhubOrder,
    toggleQuickConfig as eventhubtoggleQuickConfig,
    quickConfigDetails as eventhubquickConfigDetails,
} from 'pages/provisioning/store/event-hub';
import {
    sspSubmitOrder as azureDataFactoryOrder,
    toggleQuickConfig as azureDataFactorytoggleQuickConfig,
    quickConfigDetails as azureDataFactoryquickConfigDetails,
} from 'pages/provisioning/store/azure-data-factory';
import { sspSubmitOrder as couchbaseOrder } from 'pages/provisioning/store/couchbase';
import { copyPrevNodeDetails, updateNodesDetails } from 'pages/provisioning/store/couchbase/selecters';
import Styles from 'pages/provisioning/_components/style';
import AxiosLoader from 'components/AxiosLoader';
import { useParams, useLocation } from 'react-router-dom';
import { eiapSave } from 'utils/constant';
import {
    pECatalogDefinitionId,
    couchbaseDbDefinitionId,
    ApplicationGateWayURLCatalogDefinitionID,
    InternalApplicationGateWayURLCatalogDefinitionID,
} from 'utils/catalogDefinitionId';
import InfoImg from 'components/info-img';
import HelpImg from 'pages/provisioning/_components/helpImg';
import Btn from '../../../components/button';
import SubmitRule from '../nsg-common-rule/components/SubmitRule';

const FormCard = (props) => {
    const { catalogCode, operation } = useParams();
    const location = useLocation();
    const loading = AxiosLoader();
    const { title, children, showPreconfigBtn, indexVal, showTooltip, tooltipmessage, placement, maxWidth } = props;
    const order = useRecoilValue(sspSubmitOrder);
    const qcDetails = useRecoilValue(quickConfigDetails);
    const setQuickConfigOpen = useSetRecoilState(toggleQuickConfig);

    const sqlorder = useRecoilValue(sqlOrder);
    const sqlqcDetails = useRecoilValue(sqlquickConfigDetails);
    const setsqlQuickConfigOpen = useSetRecoilState(sqltoggleQuickConfig);

    const oracleorder = useRecoilValue(oracleOrder);
    const oracleqcDetails = useRecoilValue(oraclequickConfigDetails);
    const setOracleQuickConfigOpen = useSetRecoilState(oracletoggleQuickConfig);

    const sqlIOrder = useRecoilValue(sqlIassOrder);
    const sqlIqcDetails = useRecoilValue(sqlIassquickConfigDetails);
    const setsqlIQuickConfigOpen = useSetRecoilState(sqlIasstoggleQuickConfig);

    const storageBlobOrder = useRecoilValue(storageOrder);
    const storageBlogDetails = useRecoilValue(storagequickConfigDetails);
    const setStorageBlogQuickConfigOpen = useSetRecoilState(storagetoggleQuickConfig);

    const aksnsValOrder = useRecoilValue(aksnsOrder);
    const aksnsDetails = useRecoilValue(aksnsquickConfigDetails);
    const setAksnsQuickConfigOpen = useSetRecoilState(aksnstoggleQuickConfig);

    const keyValtOrder = useRecoilValue(keyVaultOrder);
    const keyValtDetails = useRecoilValue(keyVaultquickConfigDetails);
    const setKeyValtQuickConfigOpen = useSetRecoilState(keyVaulttoggleQuickConfig);

    const albValOrder = useRecoilValue(albOrder);
    const albDetails = useRecoilValue(albquickConfigDetails);
    const setAlbQuickConfigOpen = useSetRecoilState(albtoggleQuickConfig);

    const day2ValOrder = useRecoilValue(day2Order);
    const day2Details = useRecoilValue(day2quickConfigDetails);
    const setDay2QuickConfigOpen = useSetRecoilState(day2toggleQuickConfig);

    const day2PaaSValOrder = useRecoilValue(day2PaaSOrder);
    const day2PaaSDetails = useRecoilValue(day2PaaSquickConfigDetails);
    const setDay2PaaSQuickConfigOpen = useSetRecoilState(day2PaaStoggleQuickConfig);

    const kafkaValOrder = useRecoilValue(kafkaOrder);
    const kafkaDetails = useRecoilValue(kafkaquickConfigDetails);
    const setKafkaQuickConfigOpen = useSetRecoilState(kafkatoggleQuickConfig);

    const privateValOrder = useRecoilValue(privateEnpointOrder);
    const privateEndQCDetails = useRecoilValue(privateEnpointQuickConfigDetails);
    const setPrivateQcOpen = useSetRecoilState(privateEnpointToggleQuickConfig);

    const eventhubValOrder = useRecoilValue(eventhubOrder);
    const eventhubDetails = useRecoilValue(eventhubquickConfigDetails);
    const setEventhubQuickConfigOpen = useSetRecoilState(eventhubtoggleQuickConfig);

    const azureDataFactoryValOrder = useRecoilValue(azureDataFactoryOrder);
    const azureDataFactoryDetails = useRecoilValue(azureDataFactoryquickConfigDetails);
    const setAzureDataFactoryQuickConfigOpen = useSetRecoilState(azureDataFactorytoggleQuickConfig);

    const couchbaseValOrder = useRecoilValue(couchbaseOrder);
    const copyPrevNode = useSetRecoilState(copyPrevNodeDetails);
    const updateNodesData = useSetRecoilState(updateNodesDetails);

    const [disableButton, setDisableButton] = React.useState(false);

    const setPrevNodeChecked = (key, selected, index) => {
        updateNodesData({ key, selected, index });
    };

    const handleCheckBoxChange = (event, index) => {
        const checkedVal = event.target.checked;
        copyPrevNode({ index, checkedVal });
        setPrevNodeChecked('copyPrevChecked', checkedVal, index);
    };

    React.useEffect(() => {
        if (order?.appProfile) {
            setDisableButton(false);
        } else if (sqlorder?.appProfile) {
            setDisableButton(false);
        } else if (oracleorder?.appProfile) {
            setDisableButton(false);
        } else if (sqlIOrder?.appProfile) {
            setDisableButton(false);
        } else if (storageBlobOrder?.appProfile) {
            setDisableButton(false);
        } else if (aksnsValOrder?.appProfile) {
            setDisableButton(false);
        } else if (keyValtOrder?.appProfile) {
            setDisableButton(false);
        } else if (albValOrder?.appProfile) {
            setDisableButton(false);
        } else if (day2ValOrder?.appProfile) {
            setDisableButton(false);
        } else if (day2PaaSValOrder?.appProfile) {
            setDisableButton(false);
        } else if (kafkaValOrder?.appProfile) {
            setDisableButton(false);
        } else if (privateValOrder?.appProfile) {
            setDisableButton(false);
        } else if (eventhubValOrder?.appProfile) {
            setDisableButton(false);
        } else if (azureDataFactoryValOrder?.appProfile) {
            setDisableButton(false);
        } else {
            setDisableButton(true);
        }
    }, [
        order?.appProfile,
        sqlorder?.appProfile,
        oracleorder?.appProfile,
        sqlIOrder?.appProfile,
        storageBlobOrder?.appProfile,
        aksnsValOrder?.appProfile,
        keyValtOrder?.appProfile,
        albValOrder?.appProfile,
        day2ValOrder?.appProfile,
        day2PaaSValOrder?.appProfile,
        kafkaValOrder?.appProfile,
        privateValOrder?.appProfile,
        eventhubValOrder?.appProfile,
        azureDataFactoryValOrder?.appProfile,
    ]);

    return (
        <Paper elevation={0} sx={Styles.formCard.paper}>
            <Box sx={Styles.formCard.wrapper}>
                <Typography sx={Styles.formCard.heading} variant="subtitle1">
                    <Stack direction="row">
                        {title}
                        <span style={{ marginLeft: '7px', marginTop: '2px' }}>
                            {couchbaseDbDefinitionId?.includes(catalogCode) && title === 'Add Bucket' && <InfoImg title="Total 32 bucket names are allowed" />}
                        </span>
                        <span style={{ marginLeft: '7px', marginTop: '2.5px' }}>
                            {showTooltip && <HelpImg title={tooltipmessage} placement={placement} maxWidth={maxWidth} />}
                        </span>{' '}
                    </Stack>
                </Typography>
                {!couchbaseDbDefinitionId?.includes(catalogCode) &&
                    !pECatalogDefinitionId?.includes(catalogCode) &&
                    !location?.pathname?.includes('day2-operation') &&
                    ![...ApplicationGateWayURLCatalogDefinitionID, ...InternalApplicationGateWayURLCatalogDefinitionID]?.includes(catalogCode) &&
                    operation !== eiapSave &&
                    title === 'Application' &&
                    showPreconfigBtn && (
                        <Tooltip title={disableButton ? 'Please Select the Application first' : ''} arrow>
                            <span>
                                <Btn
                                    data-testid="preconfig-btn"
                                    sx={Styles.formCard.preconfigbtn}
                                    className="btn-disabled custom-btn"
                                    color="cmpPrimary"
                                    variant="outlined"
                                    disabled={disableButton}
                                    onClick={() => {
                                        if (!loading) {
                                            if (order?.appProfile && qcDetails === undefined) setQuickConfigOpen(true);
                                            if (sqlorder?.appProfile && sqlqcDetails === undefined) setsqlQuickConfigOpen(true);
                                            if (oracleorder?.appProfile && oracleqcDetails === undefined) setOracleQuickConfigOpen(true);
                                            if (sqlIOrder?.appProfile && sqlIqcDetails === undefined) setsqlIQuickConfigOpen(true);
                                            if (storageBlobOrder?.appProfile && storageBlogDetails === undefined) setStorageBlogQuickConfigOpen(true);
                                            if (aksnsValOrder?.appProfile && aksnsDetails === undefined) setAksnsQuickConfigOpen(true);
                                            if (keyValtOrder?.appProfile && keyValtDetails === undefined) setKeyValtQuickConfigOpen(true);
                                            if (albValOrder?.appProfile && albDetails === undefined) setAlbQuickConfigOpen(true);
                                            if (day2ValOrder?.appProfile && day2Details === undefined) setDay2QuickConfigOpen(true);
                                            if (day2PaaSValOrder?.appProfile && day2PaaSDetails === undefined) setDay2PaaSQuickConfigOpen(true);
                                            if (kafkaValOrder?.appProfile && kafkaDetails === undefined) setKafkaQuickConfigOpen(true);
                                            if (privateValOrder?.appProfile && privateEndQCDetails === undefined) setPrivateQcOpen(true);
                                            if (eventhubValOrder?.appProfile && eventhubDetails === undefined) setEventhubQuickConfigOpen(true);
                                            if (azureDataFactoryValOrder?.appProfile && azureDataFactoryDetails === undefined)
                                                setAzureDataFactoryQuickConfigOpen(true);
                                        }
                                    }}
                                >
                                    PreConfig
                                </Btn>
                            </span>
                        </Tooltip>
                    )}
                {couchbaseDbDefinitionId?.includes(catalogCode) && title !== 'Node 1' && title === `Node ${(indexVal + 1).toString()}` && (
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={couchbaseValOrder?.nodes?.[indexVal]?.copyPrevChecked}
                                    onChange={(event) => handleCheckBoxChange(event, indexVal)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    disabled={couchbaseValOrder?.nodes?.[0]?.osTypeVersion === null}
                                />
                            }
                            label="Copy from previous"
                        />
                    </FormGroup>
                )}
                {title === 'NSG Rule' && <SubmitRule />}
            </Box>
            <Divider variant="middle" />
            <Stack direction="column" spacing={2} sx={Styles.formCard.column}>
                {children}
            </Stack>
        </Paper>
    );
};

FormCard.propTypes = {
    title: PropTypes.string,
    children: PropTypes.element.isRequired,
    showPreconfigBtn: PropTypes.bool,
    indexVal: PropTypes.number,
    showTooltip: PropTypes.bool,
    tooltipmessage: PropTypes.any,
    placement: PropTypes.string,
    maxWidth: PropTypes.string,
};

FormCard.defaultProps = {
    title: 'title here',
    showPreconfigBtn: true,
    indexVal: 0,
    showTooltip: false,
    tooltipmessage: <>message</>,
    placement: 'bottom',
    maxWidth: 'auto',
};

export default React.memo(FormCard);
