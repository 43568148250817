/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { getLoggedUserGPID } from 'utils';
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    CircularProgress,
    Tooltip,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import { Link, useHistory } from 'react-router-dom';
import ErrorFallback from 'components/error-fallback';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import axios from 'axios';
import { endPoints } from 'pages/dlops-dashboard/api/endpoint';
import { Item, StyledTableHeader, StyledTableCell, style } from './style';
import { ProgressIcon } from '../style';
import stickyNotes from '../../../assets/images/dlopsImages/sticky-note-solid.svg';
import TraiangleExclamation from '../../../assets/images/dlopsImages/triangle-exclamation-solid.svg';
import AccessToken from '../components/accessToken/accessToken';

const DlopsModels = () => {
    const { getAccessToken, handleTokenError } = AccessToken();
    // const [columnNamePrediction, setColumnNamePrediction] = useState('');
    const [isLoading, setLoading] = useState(true);
    const history = useHistory();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [value, setValue] = React.useState('1');
    const [activeModelData, setActiveModelData] = useState([]);
    const [conditionalModel, setConditionalModel] = useState(false);
    const [driftColumnStatus, setDriftColumnStatus] = useState(false);
    const [activeModelConditionalData, setActiveModelConditionalData] = useState({});
    const [archivedThresholdMetric, setArchivedThresholdMetric] = useState('');
    const [conditionalArchivedModel, setconditionalArchivedModel] = useState(false);
    const [nonActiveModel, setNonActiveModel] = useState([]);
    const [newModelData, setNewModelData] = useState([]);
    const [predictionModelMetricThreshold, setPredictionModelMetricThreshold] = useState('');
    const [open, setOpen] = useState(false);
    const [enableEditButton, setEnableEditButton] = useState(false);
    const [getBaselineStatus, setBaselineStatus] = useState(null);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const conditionalModelColumns = [
        {
            name: 'Avg. confidence score',
        },
        {
            name: 'Avg. drift distance',
        },
    ];

    const driftedDatasetColumn = [
        {
            name: 'Drifted dataset',
            maxWidth: '130px',
        },
    ];

    const activeColumns = [
        {
            name: 'Model',
        },
        ...(enableEditButton || getBaselineStatus === 'failed' ? [{ name: 'Action' }] : []),
        {
            name: 'Version',
        },
        { name: 'Stage' },
        { name: 'Type' },
        {
            name: 'Framework',
        },
        { name: 'Data source version' },
        ...(conditionalModel ? conditionalModelColumns : []),
        ...(driftColumnStatus ? driftedDatasetColumn : []),
    ];

    const nonActivecolumns = [
        {
            name: 'Model',
        },
        {
            name: 'Version',
        },
        { name: 'Stage' },
        { name: 'Type' },
        {
            name: 'Framework',
        },
        { name: 'Data source version' },
        ...(conditionalArchivedModel ? conditionalModelColumns : []),
    ];
    const newModelColumns = [
        {
            name: 'Model',
        },
        {
            name: 'Version',
        },
        { name: 'Stage' },
        { name: 'Type' },
        {
            name: 'Framework',
        },
        { name: 'Data source version' },
    ];

    const updateNewModelData = (modelName, sortByVersionData) => {
        const filterByModelName = sortByVersionData.filter((model) => model.name === modelName);
        const groupedData = filterByModelName.reduce((groups, item) => {
            if (item.current_stage === 'None') {
                if (!groups[item.name] || parseInt(item.version, 10) > parseInt(groups[item.name].version, 10)) {
                    // eslint-disable-next-line no-param-reassign
                    groups[item.name] = item;
                }
            }
            return groups;
        }, {});

        // Convert the grouped data back into an array
        const latestNoneVersions = Object.values(groupedData);
        setNewModelData(latestNoneVersions);
    };

    const getModelOnboardedMetrics = (filterActiveModel) => {
        const modelMetricsTag = filterActiveModel[0].tags.find((tag) => tag.key === 'model_metrics');
        const extractedValues = {};
        if (modelMetricsTag) {
            const modelMetricsValue = modelMetricsTag.value;
            let modelMetrics;
            try {
                modelMetrics = JSON.parse(modelMetricsValue.replace(/'/g, '"'));
            } catch (error) {
                const metricsArray = modelMetricsValue.split(',');
                metricsArray.forEach((metric) => {
                    const trimmedMetric = metric.trim();
                    const metricTag = filterActiveModel[0].tags.find((tag) => tag.key === trimmedMetric);
                    if (metricTag) {
                        extractedValues[trimmedMetric] = parseFloat(metricTag.value);
                    }
                });
            }
            if (Array.isArray(modelMetrics) && modelMetrics.includes('accuracy')) {
                const accuracyTag = filterActiveModel[0].tags.find((tag) => tag.key === 'accuracy');
                if (accuracyTag) {
                    let accuracyValues;
                    try {
                        accuracyValues = JSON.parse(accuracyTag.value);
                        if (Array.isArray(accuracyValues)) {
                            extractedValues.accuracy = parseFloat(accuracyValues[1]);
                        } else {
                            extractedValues.accuracy = 0.75;
                        }
                    } catch (error) {
                        extractedValues.accuracy = 0.75;
                    }
                } else {
                    extractedValues.accuracy = 0.75;
                }
            } else {
                extractedValues.accuracy = 0.75;
            }
        }
        const formattedValues = Object.keys(extractedValues).map((key) => ({
            key: 'accuracy',
            value: extractedValues[key],
        }));
        return formattedValues;
    };

    const getDriftAccuracy = async (filterActiveModel) => {
        const runIds = filterActiveModel.map((item) => item.run_id || item.model_id);
        const modelVersionStorage = localStorage.getItem('modelVersionsCheck');
        let accuracyThreshold;
        if (modelVersionStorage === false) {
            accuracyThreshold = filterActiveModel[0].tags.find((tag) => tag.key === 'model_metric_threshold');
        } else {
            accuracyThreshold = getModelOnboardedMetrics(filterActiveModel);
        }
        const payload = { model_id: runIds[0] };
        setPredictionModelMetricThreshold(accuracyThreshold);
        try {
            const accessToken = await getAccessToken();
            const response = await axios.post(endPoints.accuracyDistanceForActiveModel, payload, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            if (response.data.status === 'success') {
                if (Object.keys(response?.data?.data).length !== 0 && response?.data?.data !== 'None') {
                    setConditionalModel(true);
                    setActiveModelConditionalData(response?.data?.data);
                    const data = response?.data?.data;
                    const currentPrediction = parseFloat(data?.PredictionAccuracy?.currentMetricValue);
                    // const thresholdPrediction = data?.PredictionAccuracy?.threshold;
                    // let thresholdPrediction = accuracyThreshold ? accuracyThreshold.value : data?.PredictionAccuracy?.autoThre;
                    let thresholdPrediction;
                    if (accuracyThreshold && Array.isArray(accuracyThreshold)) {
                        thresholdPrediction = parseFloat(accuracyThreshold[0].value);
                    } else if (accuracyThreshold) {
                        thresholdPrediction = parseFloat(accuracyThreshold.value);
                    } else {
                        thresholdPrediction = data?.PredictionAccuracy?.autoThre;
                    }
                    thresholdPrediction = parseFloat(thresholdPrediction);
                    const currentEuclidean = parseFloat(data?.EuclideanDistance?.currentMetricValue);
                    const thresholdEuclidean = data?.EuclideanDistance?.user_threshold;

                    const prediction = currentPrediction >= thresholdPrediction;
                    const euclidean = currentEuclidean < thresholdEuclidean;
                    if (prediction === true && euclidean === true) {
                        setDriftColumnStatus(false);
                    } else if (prediction === false && euclidean === true) {
                        setDriftColumnStatus(true);
                    } else if (prediction === false && euclidean === false) {
                        setDriftColumnStatus(true);
                    } else if (prediction === true && euclidean === false) {
                        setDriftColumnStatus(true);
                    }
                }
            }

            return response.data;
        } catch (error) {
            return handleTokenError(error, endPoints.accuracyDistanceForActiveModel, 'post', payload);
        }
    };
    const updateDriftAccuracyValue = (latestNoneVersions, driftAccuracy) => {
        try {
            const accuracyMapping = driftAccuracy?.data;

            if (accuracyMapping) {
                const updatedModelData = latestNoneVersions.map((model) => {
                    // If accuracyInfo is available, update the model object
                    return {
                        ...model,
                        EuclideanDistance: accuracyMapping.EuclideanDistance,
                        PredictionAccuracy: accuracyMapping.PredictionAccuracy,
                    };
                });
                return updatedModelData;
            }
            const updatedOriginalData = latestNoneVersions.map((model) => ({
                ...model,
                EuclideanDistance: undefined,
                PredictionAccuracy: undefined,
            }));

            return updatedOriginalData;
        } catch (error) {
            // Handle the error here, e.g., log it or return a default value
            return error;
        }
    };
    const fetchObservabilityModel = async (runId, accessToken) => {
        try {
            const response = await axios.get(endPoints.testObserveModelEndPoint(runId), {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            return response?.data;
        } catch (error) {
            return handleTokenError(error, endPoints.testObserveModelEndPoint(runId), 'get', null);
        }
    };

    const getObservabilityModel = async (latestActiveVersion) => {
        const runId = latestActiveVersion.map((item) => item.run_id || item.model_id);
        try {
            const accessToken = await getAccessToken();
            const response = await fetchObservabilityModel(runId, accessToken);
            const baselineStatus = response?.data?.model?.baseline_ingestion_status?.toLowerCase();
            if (response?.response?.status === 404 && response?.response?.data?.message.includes('No model with this model_id')) {
                setEnableEditButton(true);
            } else if (baselineStatus === 'failed') {
                setEnableEditButton(true);
                setBaselineStatus('failed');
            } else {
                setEnableEditButton(false);
                setBaselineStatus(baselineStatus || '');
            }
        } catch (error) {
            if (error?.response?.status === 404 && error?.response?.data?.message.includes('No model with this model_id')) {
                // Enable the Edit button if error is 404 and message contains "No model with this model_id"
                setEnableEditButton(true);
            } else {
                // Disable the Edit button in case of other errors
                setEnableEditButton(false);
            }
        }
    };

    const updateActiveModelData = (modelName, sortByVersionData) => {
        // const filterActiveModel = sortByVersionData.filter((item) => item.current_stage === 'Production');
        const filterByModelName = sortByVersionData.filter((model) => model.name === modelName);
        const filteredData = filterByModelName.filter((item) => item.current_stage === 'Production');
        const sortByLatestVersions = filteredData.sort((a, b) => {
            const versionA = parseInt(a.version, 10);
            const versionB = parseInt(b.version, 10);

            return versionB - versionA;
        });
        const latestOneVersion = sortByLatestVersions.slice(0, 1);
        getObservabilityModel(latestOneVersion);
        let driftAccuracy;
        if (latestOneVersion.length > 0) {
            try {
                driftAccuracy = getDriftAccuracy(latestOneVersion);
            } catch (accuracyError) {
                return accuracyError;
            }
        }
        const updatedDriftData = updateDriftAccuracyValue(latestOneVersion, driftAccuracy);
        // const sortByLatestVersion = updatedDriftData.sort((a, b) => {
        //     const versionA = parseInt(a.version, 10);
        //     const versionB = parseInt(b.version, 10);

        //     return versionB - versionA;
        // });
        // const firstFiveVersion = sortByLatestVersion.slice(0, 1);
        return setActiveModelData(updatedDriftData);
    };
    const getModelData = async (modelName, gpid) => {
        try {
            const accessToken = await getAccessToken();

            const response = await axios.get(endPoints.registeredModelVersions(modelName), {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    gpid,
                },
            });
            return response.data;
        } catch (error) {
            return handleTokenError(error, endPoints.registeredModelVersions(modelName), 'get', null);
        }
    };

    function removeMatchingObjects(dataArray, comparisonArray) {
        return dataArray.filter((dataItem) => {
            const matchingItem = comparisonArray.find(
                (comparisonItem) => comparisonItem.version === dataItem.version && comparisonItem.current_stage === dataItem.current_stage
            );
            return !matchingItem;
        });
    }

    const findThresholdForMlFlow = (data) => {
        const thresholdValuesWithRunId = data
            .map((item) => {
                const runId = item.run_id;

                return (item.tags || []).filter((tag) => tag.key === 'model_metric_threshold').map((tag) => ({ runId, key: tag.key, value: tag.value }));
            })
            .flat();
        return thresholdValuesWithRunId;
    };

    const parseValue = (accuracyValue) => {
        try {
            const parsedValue = JSON.parse(accuracyValue.replace(/'/g, '"'));
            if (Array.isArray(parsedValue)) {
                return parsedValue.length > 1 ? parseFloat(parsedValue[1]) : parseFloat(parsedValue[0]);
            }
            if (typeof parsedValue === 'string' && parsedValue.includes(',')) {
                const splitValues = parsedValue.split(',').map((val) => val.trim());
                return parseFloat(splitValues[1] || splitValues[0]);
            }
            return parseFloat(parsedValue);
        } catch (error) {
            // If parsing fails and the value is a comma-separated string
            if (typeof accuracyValue === 'string' && accuracyValue.includes(',')) {
                const splitValues = accuracyValue.split(',').map((val) => val.trim());
                return parseFloat(splitValues[1] || splitValues[0]);
            }
            // Return the value directly if no special handling is needed
            return parseFloat(accuracyValue);
        }
    };

    const findThresholdForUIOnboarded = (data) => {
        const thresholdValuesWithRunId = data.map((item) => {
            const runId = item.model_id;
            const modelMetricsTag = (item.tags || []).find((tag) => tag.key === 'model_metrics');

            let thresholdValue = null;
            if (modelMetricsTag) {
                const modelMetricsValue = modelMetricsTag.value;
                let modelMetrics;
                try {
                    modelMetrics = JSON.parse(modelMetricsValue.replace(/'/g, '"'));
                } catch (error) {
                    // Handle parsing error if needed
                }
                if (Array.isArray(modelMetrics) && modelMetrics.includes('accuracy')) {
                    const accuracyTag = (item.tags || []).find((tag) => tag.key === 'accuracy');
                    if (accuracyTag) {
                        thresholdValue = parseValue(accuracyTag.value);
                    }
                }
            }
            return { runId, key: 'accuracy', value: thresholdValue || 0.75 };
        });
        return thresholdValuesWithRunId;
    };

    const getAccuracyArchiveDistance = async (filterNonActiveModel) => {
        const runIds = filterNonActiveModel.map((item) => item.run_id || item.model_id);
        const modelVersionStorage = localStorage.getItem('modelVersionsCheck');
        let accuracyThreshold;
        if (modelVersionStorage === false) {
            accuracyThreshold = findThresholdForMlFlow(filterNonActiveModel);
        } else {
            accuracyThreshold = findThresholdForUIOnboarded(filterNonActiveModel);
        }

        setArchivedThresholdMetric(accuracyThreshold);
        const payload = { model_ids: runIds };
        try {
            const accessToken = await getAccessToken();
            const response = await axios.post(endPoints.accuracyDistanceForNonActiveModel, payload, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            if (response?.data?.status === 'success') {
                if (Array.isArray(response?.data?.data) && response?.data?.data.length > 0) {
                    response.data.data.forEach((dataItem) => {
                        // const modelId = dataItem.model_id;
                        const driftMetrics = dataItem.drift_metrics;
                        if (
                            driftMetrics.PredictionAccuracy.currentMetricValue !== 'No data' ||
                            driftMetrics.EuclideanDistance.currentMetricValue !== 'No data'
                        ) {
                            setconditionalArchivedModel(true);
                        }
                    });
                }
            }

            return response?.data?.data;
        } catch (error) {
            return handleTokenError(error, endPoints.accuracyDistanceForNonActiveModel, 'post', payload);
        }
    };

    const driftAccuracyArchived = (latestNoneVersion, driftAccuracyArray) => {
        try {
            if (driftAccuracyArray && driftAccuracyArray.length > 0) {
                const mergedData = latestNoneVersion.map((model) => {
                    // Find the matching driftAccuracy object based on model_id
                    const driftAccuracy = driftAccuracyArray.find((accuracy) => accuracy.model_id === (model.run_id || model.model_id));
                    if (driftAccuracy) {
                        const currentPredictionValue = driftAccuracy?.drift_metrics?.PredictionAccuracy?.currentMetricValue;

                        let currentPrediction;
                        if (currentPredictionValue !== 'No data') {
                            currentPrediction = parseFloat(currentPredictionValue);

                            // eslint-disable-next-line no-restricted-globals
                            if (isNaN(currentPrediction)) {
                                currentPrediction = 0;
                            }
                        } else {
                            currentPrediction = 0;
                        }
                        return {
                            ...model,
                            PredictionAccuracy: driftAccuracy.drift_metrics.PredictionAccuracy,
                            EuclideanDistance: driftAccuracy.drift_metrics.EuclideanDistance,
                        };
                    }
                    return {
                        ...model,
                        EuclideanDistance: undefined,
                        PredictionAccuracy: undefined,
                    };
                });
                return mergedData;
            }
            const updatedOriginalData = latestNoneVersion.map((model) => ({
                ...model,
                EuclideanDistance: undefined,
                PredictionAccuracy: undefined,
            }));
            return updatedOriginalData;
        } catch (error) {
            return error;
        }
    };
    // eslint-disable-next-line consistent-return
    const updateNonActiveModelData = async (modelName, sortByVersionData) => {
        const filterByModelName = sortByVersionData.filter((model) => model.name === modelName);
        const filteredModels = filterByModelName.filter((model) => model.current_stage === 'Archived' || model.current_stage === 'None');
        const groupedData = filteredModels.reduce((groups, item) => {
            if (item.current_stage === 'None') {
                if (!groups[item.name] || parseInt(item.version, 10) > parseInt(groups[item.name].version, 10)) {
                    // eslint-disable-next-line no-param-reassign
                    groups[item.name] = item;
                }
            }
            return groups;
        }, {});

        // Convert the grouped data back into an array
        const latestNoneVersions = Object.values(groupedData);
        const filteredremoveMatchingObjects = removeMatchingObjects(filteredModels, latestNoneVersions);
        const sortByLatestVersion = filteredremoveMatchingObjects.sort((a, b) => {
            const versionA = parseInt(a.version, 10);
            const versionB = parseInt(b.version, 10);

            return versionB - versionA;
        });
        let driftAccuracy;
        if (sortByLatestVersion.length > 0) {
            try {
                driftAccuracy = await getAccuracyArchiveDistance(sortByLatestVersion);
            } catch (accuracyError) {
                return accuracyError;
            }
        }

        const updatedDriftData = driftAccuracyArchived(sortByLatestVersion, driftAccuracy);

        //  const firstThreeRecord = sortByLatestVersion.slice(0, 3);
        setNonActiveModel(updatedDriftData);
    };

    const getRegisteredDlopsModelVersion = async (accessTokenPromise, projectName) => {
        setLoading(true);
        try {
            const accessToken = await accessTokenPromise;
            const response = await axios.get(endPoints.registeredModelVersionsFromDlops(projectName), {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    Accept: 'application/json',
                },
            });
            setLoading(false);
            return response?.data;
        } catch (error) {
            setLoading(false);
            return error;
        }
    };
    // eslint-disable-next-line consistent-return
    const goToModelVersion = async () => {
        try {
            setLoading(true);
            const modelName = localStorage.getItem('modelName');
            const getProjectNameFromStorage = localStorage.getItem('project_name');
            const newAccessToken = localStorage.getItem('accessToken');
            const modelVersionStorage = localStorage.getItem('modelVersionsCheck');
            let response;
            let registeredModelResponse;
            let onBoardModelResponse;
            const gpid = getLoggedUserGPID();
            if (modelVersionStorage === 'false') {
                response = await getModelData(modelName, gpid);
                registeredModelResponse = response?.model_versions;
            } else {
                response = await getRegisteredDlopsModelVersion(newAccessToken, getProjectNameFromStorage);
                onBoardModelResponse = response;
            }
            const filterResponse = modelVersionStorage === 'false' ? registeredModelResponse : onBoardModelResponse;
            const filterByProject = filterResponse.filter((model) =>
                model?.tags?.some((tag) => tag.value.toLowerCase() === getProjectNameFromStorage.toLowerCase())
            );
            const filterByModelName = filterByProject.filter((mName) => mName.name === modelName);
            updateActiveModelData(modelName, filterByModelName);
            updateNonActiveModelData(modelName, filterByModelName);
            updateNewModelData(modelName, filterByModelName);
            localStorage.setItem('modelnameByVersion', JSON.stringify(filterByModelName));
            setLoading(false);
        } catch (error) {
            setLoading(false);
            return error;
        }
    };

    const goToDashboard = (item) => {
        localStorage.setItem('modelDetails', JSON.stringify(item));
        localStorage.setItem('run_Id', item?.run_id || item?.model_id);
        history.push(`/dlops-model-observability?${item?.run_id} || ${item?.model_id}`);
    };
    useEffect(() => {
        goToModelVersion();
    }, []);

    const PredictionAccuracyContent = ({ isPredictionAccurate, currentValue }) => (
        <div style={{ display: 'flex', textAlign: 'center' }}>
            {currentValue !== 'No data' && (
                <span>
                    {isPredictionAccurate ? (
                        <Tooltip title={<span style={{ fontSize: '1.2em' }}>Average prediction score is within a healthy range</span>}>
                            <Typography
                                style={{
                                    width: '90px',
                                    borderRadius: '6px',
                                    background: '#008553 0% 0% no-repeat padding-box',
                                    opacity: 1,
                                    padding: '10px',
                                    textAlign: 'center',
                                    color: 'white',
                                }}
                            >
                                {currentValue !== 'No data' ? `${(parseFloat(currentValue) * 100).toFixed(0)}%` : 'No data'}
                            </Typography>
                        </Tooltip>
                    ) : (
                        <Tooltip title={<span style={{ fontSize: '1.2em' }}>Average prediction score is outside the healthy range</span>}>
                            <Typography
                                style={{
                                    width: '90px',
                                    borderRadius: '6px',
                                    background: '#FFBF1C 0% 0% no-repeat padding-box',
                                    opacity: 1,
                                    padding: '10px',
                                    textAlign: 'center',
                                }}
                            >
                                <img src={TraiangleExclamation} alt="warning icon" style={{ marginRight: '20px', textAlign: 'center' }} />
                                <span style={{ position: 'relative', right: '12px', bottom: '0px', fontSize: '14px' }}>
                                    {currentValue !== 'No data' ? `${(parseFloat(currentValue) * 100).toFixed(0)}%` : 'No data'}
                                </span>
                            </Typography>
                        </Tooltip>
                    )}
                </span>
            )}
        </div>
    );

    const conditionalNonActiveColumns = (row) => {
        const modelVersionStorage = localStorage.getItem('modelVersionsCheck');
        const currentPrediction = row?.PredictionAccuracy?.currentMetricValue;
        let thresholdPredictionObject;
        if (currentPrediction !== 'No data') {
            if (modelVersionStorage === false) {
                thresholdPredictionObject = archivedThresholdMetric.find(
                    (threshold) => threshold.runId === row.run_id && threshold.key === 'model_metric_threshold'
                );
            } else {
                thresholdPredictionObject = archivedThresholdMetric.find((threshold) => threshold.runId === row.model_id && threshold.key === 'accuracy');
            }
            const thresholdPrediction = parseFloat(thresholdPredictionObject?.value);
            const isPredictionAccurate = currentPrediction >= thresholdPrediction;

            return <PredictionAccuracyContent isPredictionAccurate={isPredictionAccurate} currentValue={currentPrediction} />;
        }
        return <div>No data</div>;
    };

    const conditionalEuclideanColumn = (row) => {
        const currentEuclidean = row?.EuclideanDistance?.currentMetricValue;

        // if (!currentEuclidean || currentEuclidean === 'No data') {
        //     return <div>No data</div>;
        // }

        const thresholdEuclidean = parseFloat(row?.EuclideanDistance?.user_threshold);
        const isEuclideanDistanceAccurate = currentEuclidean < thresholdEuclidean;
        if (currentEuclidean !== 'No data') {
            return (
                <div style={{ display: 'flex', textAlign: 'center' }}>
                    <span>
                        {isEuclideanDistanceAccurate ? (
                            <Tooltip
                                style={{ fontSize: '10px' }}
                                title={
                                    <span style={{ fontSize: '1.2em' }}>
                                        Average distance between image clusters of prediction and the training data is within a healthy range
                                    </span>
                                }
                            >
                                <Typography
                                    style={{
                                        width: '90px',
                                        borderRadius: '6px',
                                        background: '#008553 0% 0% no-repeat padding-box',
                                        opacity: 1,
                                        padding: '10px',
                                        textAlign: 'center',
                                        color: 'white',
                                    }}
                                >
                                    {currentEuclidean}
                                </Typography>
                            </Tooltip>
                        ) : (
                            <Tooltip
                                title={
                                    <span style={{ fontSize: '1.2em' }}>
                                        Average distance between image clusters of prediction and the training data is outside the healthy range
                                    </span>
                                }
                            >
                                <Typography
                                    style={{
                                        width: '90px',
                                        borderRadius: '6px',
                                        background: '#FFBF1C 0% 0% no-repeat padding-box',
                                        opacity: 1,
                                        padding: '10px',
                                        textAlign: 'center',
                                    }}
                                >
                                    <img src={TraiangleExclamation} alt="warning icon" style={{ marginRight: '20px', textAlign: 'center' }} />
                                    <span style={{ position: 'relative', right: '12px', bottom: '0px', fontSize: '14px' }}>{currentEuclidean}</span>
                                </Typography>
                            </Tooltip>
                        )}
                    </span>
                    {/* <span style={{ marginTop: '0px' }}>&nbsp;&nbsp;{currentEuclidean}</span> */}
                </div>
            );
        }

        return <div>No data</div>;
    };
    const handleNavigation = () => {
        history.push('/dlops-registered-models');
    };
    const handleShowDriftData = (row) => {
        localStorage.setItem('viewDriftDataSetVersion', JSON.stringify(row));
        history.push('/dlops-drift-images');
    };

    const conditionalProductionActiveColumns = () => {
        const currentPrediction = parseFloat(activeModelConditionalData?.PredictionAccuracy?.currentMetricValue);
        const multiplyPrediction = parseFloat(activeModelConditionalData?.PredictionAccuracy?.currentMetricValue) * 100;
        // const thresholdPrediction = parseFloat(predictionModelMetricThreshold.value);
        let thresholdPrediction;
        if (Array.isArray(predictionModelMetricThreshold) && predictionModelMetricThreshold.length > 0) {
            thresholdPrediction = parseFloat(predictionModelMetricThreshold[0].value);
        } else {
            thresholdPrediction = parseFloat(predictionModelMetricThreshold.value);
        }
        const isPredictionAccurate = currentPrediction >= thresholdPrediction;
        if (activeModelConditionalData?.PredictionAccuracy?.currentMetricValue === 'No Data') {
            return null;
        }
        const icon = isPredictionAccurate ? (
            <Tooltip title={<span style={{ fontSize: '1.2em' }}>Average prediction score was within a healthy range</span>}>
                <Typography
                    style={{
                        width: '90px',
                        borderRadius: '6px',
                        background: '#008553 0% 0% no-repeat padding-box',
                        opacity: 1,
                        textAlign: 'center',
                        padding: '10px',
                        color: 'white',
                    }}
                >
                    {activeModelConditionalData?.PredictionAccuracy?.currentMetricValue !== 'No data' ? `${multiplyPrediction.toFixed(0)}%` : 'No data'}
                </Typography>
            </Tooltip>
        ) : (
            <Tooltip title={<span style={{ fontSize: '1.2em' }}>Average prediction score was outside the healthy range</span>}>
                <Typography
                    style={{
                        width: '90px',
                        borderRadius: '6px',
                        background: '#FFBF1C 0% 0% no-repeat padding-box',
                        opacity: 1,
                        padding: '10px',
                        textAlign: 'center',
                    }}
                >
                    <img src={TraiangleExclamation} alt="warning icon" style={{ marginRight: '20px', textAlign: 'center' }} />
                    <span style={{ position: 'relative', right: '12px', bottom: '0px', fontSize: '14px' }}>
                        {activeModelConditionalData?.PredictionAccuracy?.currentMetricValue !== 'No data' ? `${multiplyPrediction.toFixed(0)}%` : 'No data'}
                    </span>
                </Typography>
            </Tooltip>
        );

        return <Typography style={{ display: 'flex' }}>{icon}</Typography>;
    };

    const conditionalEuclideanActiveColumns = () => {
        const currentEuclidean = parseFloat(activeModelConditionalData?.EuclideanDistance?.currentMetricValue);
        const thresholdEuclidean = parseFloat(activeModelConditionalData?.EuclideanDistance?.user_threshold);
        const isEuclideanDistanceAccurate = currentEuclidean < thresholdEuclidean;
        if (activeModelConditionalData?.EuclideanDistance?.currentMetricValue === 'No Data') {
            return null;
        }
        const icon = isEuclideanDistanceAccurate ? (
            <Tooltip
                title={
                    <span style={{ fontSize: '1.2em' }}>
                        Average distance between image clusters of prediction and the training data was within a healthy range
                    </span>
                }
            >
                <Typography
                    style={{
                        width: '90px',
                        borderRadius: '6px',
                        background: '#008553 0% 0% no-repeat padding-box',
                        opacity: 1,
                        padding: '10px',
                        textAlign: 'center',
                    }}
                >
                    <span style={{ color: 'white' }}>
                        {activeModelConditionalData?.EuclideanDistance?.currentMetricValue !== 'No data'
                            ? `${activeModelConditionalData?.EuclideanDistance?.currentMetricValue}`
                            : 'No data'}
                    </span>
                </Typography>
            </Tooltip>
        ) : (
            <Tooltip
                title={
                    <span style={{ fontSize: '1.2em' }}>
                        Average distance between image clusters of prediction and the training data was outside the healthy range
                    </span>
                }
            >
                <Typography
                    style={{
                        width: '90px',
                        borderRadius: '6px',
                        background: '#FFBF1C 0% 0% no-repeat padding-box',
                        opacity: 1,
                        textAlign: 'center',
                        padding: '10px',
                    }}
                >
                    <img src={TraiangleExclamation} alt="warning icon" style={{ textAlign: 'center', marginRight: '20px' }} />
                    <span style={{ position: 'relative', right: '12px', bottom: '0px', fontSize: '14px' }}>
                        {activeModelConditionalData?.EuclideanDistance?.currentMetricValue !== 'No data'
                            ? `${activeModelConditionalData?.EuclideanDistance?.currentMetricValue}`
                            : 'No data'}
                    </span>
                </Typography>
            </Tooltip>
        );

        return (
            <div style={{ display: 'flex' }}>
                <div>{icon}</div>
            </div>
        );
    };
    const handleEdit = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleConfirmEdit = (activeModel) => {
        history.push('/dlops-edit-existing-model', {
            state: { activeData: activeModel[0] },
        });
        setOpen(false);
    };
    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Helmet>
                <title>DLOPS Dashboard</title>
            </Helmet>
            <Box style={{ marginBottom: '20px' }}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            <Item>
                                <div
                                    style={{
                                        marginLeft: '28px',
                                        marginTop: '8px',
                                        fontSize: '14px',
                                    }}
                                >
                                    <span>DLOPS</span>
                                    <span aria-hidden="true" style={{ cursor: 'pointer', fontWeight: 'bold' }} onClick={() => handleNavigation()}>
                                        /Models
                                    </span>
                                    <span style={{ fontWeight: 'bold', color: '#1378D4' }}>/Model versions</span>
                                </div>
                            </Item>
                        </Typography>
                    </Grid>
                </Grid>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    maxWidth="sm"
                    fullWidth
                    PaperProps={{
                        sx: {
                            width: '500px',
                            height: '200px',
                            maxHeight: '50vh',
                        },
                    }}
                >
                    <DialogTitle>Edit Model</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Do you want to edit model?</DialogContentText>
                    </DialogContent>
                    <DialogActions
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Button variant="outlined" onClick={handleClose} color="primary" sx={{ margin: '20px' }}>
                            No
                        </Button>
                        <Button variant="contained" onClick={() => handleConfirmEdit(activeModelData)} color="primary">
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
                {isLoading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100vh' }}>
                        <CircularProgress color="inherit" size={16} sx={ProgressIcon.circularProgressColor} />
                        <Typography variant="body2" sx={ProgressIcon.circularProgressColor} style={{ marginLeft: '10px' }}>
                            Loading
                        </Typography>
                    </Box>
                ) : (
                    <div style={style.dataTable}>
                        <div style={style.allProjectDiv}>
                            <img src={stickyNotes} alt={stickyNotes} style={style.stickyNote} />
                            <h5 style={style.projectText}>Model versions ({activeModelData.length + nonActiveModel.length + newModelData.length})</h5>
                        </div>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider', marginLeft: '25px' }}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab style={{ textTransform: 'none' }} label={`Active Models (${activeModelData.length})`} value="1" />
                                    <Tab style={{ textTransform: 'none' }} label={`Non-Active Models (${nonActiveModel.length})`} value="2" />
                                    <Tab style={{ textTransform: 'none' }} label={`New Models (${newModelData.length})`} value="3" />
                                </TabList>
                            </Box>
                            <TabPanel value="1">
                                {activeModelData.length <= 0 ? (
                                    <Typography variant="body1" style={{ textAlign: 'center', marginTop: '20px' }}>
                                        No Records to display for active Model
                                    </Typography>
                                ) : (
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    {activeColumns.map((column, index) => (
                                                        <StyledTableHeader key={index} maxWidth={column.maxWidth}>
                                                            {column.name}
                                                        </StyledTableHeader>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {activeModelData?.map((row, index1) => (
                                                    <TableRow key={index1}>
                                                        <StyledTableCell sx={{ color: '#000000', fontWeight: 'normal' }}>
                                                            <Link
                                                                to="/dlops-model-observability"
                                                                onClick={() => goToDashboard(row)}
                                                                style={{ color: '#1378D4' }}
                                                            >
                                                                {row.name}
                                                            </Link>
                                                        </StyledTableCell>
                                                        {(enableEditButton || getBaselineStatus === 'failed') && (
                                                            <StyledTableCell sx={{ color: '#000000', fontWeight: 'normal' }}>
                                                                <Button
                                                                    variant="contained"
                                                                    onClick={handleEdit}
                                                                    sx={{
                                                                        backgroundColor: '#1378D4',
                                                                        '&:hover': {
                                                                            backgroundColor: '#125c99',
                                                                        },
                                                                    }}
                                                                >
                                                                    Edit
                                                                </Button>
                                                            </StyledTableCell>
                                                        )}

                                                        <StyledTableCell sx={{ color: '#000000' }}>{row?.version}</StyledTableCell>
                                                        <StyledTableCell sx={{ color: '#000000' }}>{row?.current_stage}</StyledTableCell>
                                                        <StyledTableCell sx={{ color: '#000000' }}>
                                                            {row?.tags.find((tag) => tag.key === 'model_type')?.value || 'undefined'}
                                                        </StyledTableCell>
                                                        <StyledTableCell sx={{ color: '#000000' }}>
                                                            {row?.tags.find((tag) => tag.key === 'framework')?.value || 'undefined'}
                                                        </StyledTableCell>
                                                        <StyledTableCell sx={{ color: '#000000' }}>
                                                            {row?.tags.find((tag) => tag.key === 'baseline_datasource_version')?.value || 'undefined'}
                                                        </StyledTableCell>
                                                        {conditionalModel ? (
                                                            <>
                                                                <StyledTableCell>{conditionalProductionActiveColumns()}</StyledTableCell>
                                                                <StyledTableCell>{conditionalEuclideanActiveColumns()}</StyledTableCell>
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )}
                                                        {driftColumnStatus ? (
                                                            <StyledTableCell>
                                                                <a
                                                                    to="/model-drift-images"
                                                                    aria-hidden="true"
                                                                    style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                                                                    onClick={() => handleShowDriftData(row)}
                                                                >
                                                                    Details
                                                                </a>
                                                            </StyledTableCell>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                )}
                            </TabPanel>
                            <TabPanel value="2">
                                {nonActiveModel.length <= 0 ? (
                                    <Typography variant="body1" style={{ textAlign: 'center', marginTop: '20px' }}>
                                        No Records to display for non-active Model
                                    </Typography>
                                ) : (
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    {nonActivecolumns.map((column, index) => (
                                                        <StyledTableHeader key={index} maxWidth={column.maxWidth}>
                                                            {column.name}
                                                        </StyledTableHeader>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {nonActiveModel?.map((row, index1) => (
                                                    <TableRow key={index1}>
                                                        <StyledTableCell sx={{ color: '#000000', fontWeight: 'normal' }}>
                                                            <Link
                                                                to="/dlops-model-observability"
                                                                onClick={() => goToDashboard(row)}
                                                                style={{ color: '#1378D4' }}
                                                            >
                                                                {row.name}
                                                            </Link>
                                                        </StyledTableCell>
                                                        <StyledTableCell sx={{ color: '#000000' }}>{row?.version}</StyledTableCell>
                                                        <StyledTableCell sx={{ color: '#000000' }}>{row?.current_stage}</StyledTableCell>
                                                        <StyledTableCell sx={{ color: '#000000' }}>
                                                            {row?.tags.find((tag) => tag.key === 'model_type')?.value || 'undefined'}
                                                        </StyledTableCell>
                                                        <StyledTableCell sx={{ color: '#000000' }}>
                                                            {row?.tags.find((tag) => tag.key === 'framework')?.value || 'undefined'}
                                                        </StyledTableCell>
                                                        <StyledTableCell sx={{ color: '#000000' }}>
                                                            {row?.tags.find((tag) => tag.key === 'baseline_datasource_version')?.value || 'undefined'}
                                                        </StyledTableCell>
                                                        {conditionalArchivedModel ? (
                                                            <>
                                                                <StyledTableCell>{conditionalNonActiveColumns(row)}</StyledTableCell>
                                                                <StyledTableCell>{conditionalEuclideanColumn(row)}</StyledTableCell>
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                        {nonActiveModel && nonActiveModel.length > 0 ? (
                                            <TablePagination
                                                rowsPerPageOptions={[10, 25, 50, 100]}
                                                component="div"
                                                count={nonActiveModel.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </TableContainer>
                                )}
                            </TabPanel>
                            <TabPanel value="3">
                                {newModelData.length <= 0 ? (
                                    <Typography variant="body1" style={{ textAlign: 'center', marginTop: '20px' }}>
                                        No Records to display for new model
                                    </Typography>
                                ) : (
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    {newModelColumns.map((column, index) => (
                                                        <StyledTableHeader key={index} maxWidth={column.maxWidth}>
                                                            {column.name}
                                                        </StyledTableHeader>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {newModelData?.map((row, index1) => (
                                                    <TableRow key={index1}>
                                                        <StyledTableCell sx={{ color: '#000000', fontWeight: 'normal' }}>
                                                            <Link
                                                                to="/dlops-model-observability"
                                                                onClick={() => goToDashboard(row)}
                                                                style={{ color: '#1378D4' }}
                                                            >
                                                                {row.name}
                                                            </Link>
                                                        </StyledTableCell>
                                                        <StyledTableCell sx={{ color: '#000000' }}>{row?.version}</StyledTableCell>
                                                        <StyledTableCell sx={{ color: '#000000' }}>{row?.current_stage}</StyledTableCell>
                                                        <StyledTableCell sx={{ color: '#000000' }}>
                                                            {row?.tags.find((tag) => tag.key === 'model_type')?.value || 'undefined'}
                                                        </StyledTableCell>
                                                        <StyledTableCell sx={{ color: '#000000' }}>
                                                            {row?.tags.find((tag) => tag.key === 'framework')?.value || 'undefined'}
                                                        </StyledTableCell>
                                                        <StyledTableCell sx={{ color: '#000000' }}>
                                                            {row?.tags.find((tag) => tag.key === 'baseline_datasource_version')?.value || 'undefined'}
                                                        </StyledTableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                        {newModelData && newModelData.length > 0 ? (
                                            <TablePagination
                                                rowsPerPageOptions={[10, 25, 50, 100]}
                                                component="div"
                                                count={newModelData.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </TableContainer>
                                )}
                            </TabPanel>
                        </TabContext>
                    </div>
                )}
            </Box>
        </ErrorBoundary>
    );
};

export default DlopsModels;
