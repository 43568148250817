// IaaS Services
export const iaasVMCatalogServiceCode = 'IaaS/VM';
export const iaasOracleCatalogServiceCode = 'IaaS/Oracle';
export const iaasSQLCatalogServiceCode = 'IaaS/SQL';
export const iaasCouchbaseServiceCode = 'IaaS/Couchbase';

// PaaS Services
export const paasSQLCatalogServiceCode = 'PaaS/MSSQL';
export const storageAccountCatalogServiceCode = 'PaaS/Azure Blob';
export const kafkaCatalogServiceCode = 'PaaS/Kafka Topics';
export const AlbCatalogServiceCode = 'PaaS/ALB';
export const keyvaultCatalogServiceCode = 'PaaS/AzKeyVault';
export const eventhubCatalogServiceCode = 'PaaS/EventHubs';
export const aksCatalogServiceCode = 'PaaS/AKSNS';
export const privateEndPointCatalogServiceCode = 'PaaS/AzurePE';
export const eipStackCatalogServiceCode = 'Stack/App-Microservices';
export const adfCatalogServiceCode = 'PaaS/Data Factory';
export const day2CatalogServiceCode = 'DAY2ADDMEMBERS';
export const onBoardDevelopersServiceCode = 'DAY2DEVELOPERONBOARD';
export const ApplicationGateWayURLServiceCode = 'AZAGWURLONBOARD';
export const InternalApplicationGateWayURLServiceCode = 'AZINTAGWURLONBOARD';
